@font-face {
  font-family: "Trainline";
  src: url("TLCircular-Titling.eot");
  src: url("TLCircular-Titling.eot#iefix") format("embedded-opentype"),
  url("TLCircular-Titling.woff") format("woff"),
  url("TLCircular-Titling.ttf") format("truetype"),
  url("TLCircular-Titling.svg#TLCircularTitling") format("svg");
  font-weight: bold;
  font-style: normal;
}
