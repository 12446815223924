@import url('./node_modules/@trainline/depot-web/lib/css/themes/trainline/transitions.css');

html, body, div, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, button, input, textarea, canvas,
*:before, *:after {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  outline: none;
}

button, input, textarea, canvas {
  font: inherit;
}

input, textarea, canvas {
  outline: none;
}

button:not([data-a11y]) {
  outline: none;
}

html {
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-size: 14px; /* base font size is 16px, but the default font size is 14px */
}


svg {
  max-height: 100%;
  max-width: 100%;
}

body > iframe {
  display: none;
  visibility: hidden;
}

/* Make sure Usabilla modal is visible all times when scrolling */
.usabilla_scroller_area {
  top: 10% !important;
  position: fixed !important;
}